


















import { Component, Vue, Watch } from 'vue-property-decorator'
import MessageViewer from './components/MessageViewer.vue'
import { HomeworkController } from '@/services/request.service'

@Component({
  components: {
    MessageViewer,
  },
})
export default class SystemMessage extends Vue {
  private get isChildRoute(): boolean {
    return this.$route.name !== 'systemMessage'
  }
  private get menuList(): Array<any> {
    return [
      {
        key: 'favor',
        label: this.$t('systemMessage.favor'),
      },
      {
        key: 'approval',
        label: this.$t('systemMessage.approval'),
      },
      {
        key: 'notice',
        label: this.$t('systemMessage.notice'),
      },
    ]
  }

  private get count(): any {
    return this.$store.state.systemMessageNum
  }

  private get type(): any {
    return this.$route.params.messageType
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to): void {
    if (to.name === 'systemMessage') {
      if (!to.params.messageType) {
        this.$router
          .push({ name: 'systemMessage', params: { messageType: 'favor' } })
          .catch(err => {})
      }
    }
  }

  private changeType({ item, key, keyPath }): void {
    this.$router.push({ name: 'systemMessage', params: { messageType: key } }).catch(err => {})
  }
}


































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import moment from 'moment'
import { MessageController, MomentsController } from '@/services/request.service'
import { createPagination } from '@/constant/constant'

@Component({
  components: {},
})
export default class MessageViewer extends Vue {
  @Prop() private readonly type!: any
  private data: any = []
  private getAllDataFlag: boolean = false
  private pagination: any = createPagination({ current: 0 })
  private momentDetail: any = {}

  private getData(page = { current: 1, pageSize: this.pagination.defaultPageSize }): void {
    if (this.getAllDataFlag) return
    let condition = {
      pageCurrent: page.current,
      pageSize: page.pageSize,
      queryType: 'System',
    }
    MessageController.getToList(condition).then(res => {
      this.data = this.data.concat(
        res.data.items.map(item => ({
          ...item,
          sendTime: moment(item.sendTime).format('MM-DD HH:mm'),
        }))
      )
      this.pagination.total = res.data.totalItem
      if (this.data.length === this.pagination.total) {
        this.getAllDataFlag = true
      }
    })
  }

  private handleInfoLoad(): void {
    this.pagination.current++
    this.getData(this.pagination)
  }

  private created() {
    this.clearData()
    this.handleInfoLoad()
  }

  private toPage(): void {
    this.$router.push({ name: 'parentsMeeting' })
  }

  private clearData(): void {
    this.data = []
    this.pagination = createPagination({ current: 0 })
    this.getAllDataFlag = false
  }

  private getTitle(item): any {
    if (item.type === '1618') {
      return this.$t('systemMessage.bookSuccess')
    }
    return this.$t('systemMessage.bookSuccess')
  }
}
